import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import { VideoModalStore } from '@/compnents/videoModal.tsx';
import { API } from '@/pages/anchor/anchorManagement/api.ts';
import type { BrokerVO } from '@/pages/anchor/anchorManagement/type.ts';
import type { AnchorDetail } from '@/pages/anchorDetail/types';
import ChooseEmployerModel from '@/pages/section/sectionAdmin/components/chooseEmployer/model.tsx';
import type { SliceInfoVO } from '@/pages/section/sectionAdmin/type.ts';
import type { SysTagLibVO } from '@/pages/tagsManagement/type.ts';
import {
  type BaseData,
  type FilterTreeSelect,
  type FilterTreeSelectItem,
  NormalProgramme,
  type PaginationData,
  extractValuesByKey,
  getWxUrl,
  request,
  transformKeys,
} from '@/utils';
import { api } from '@/utils/api.ts';
import { TreeSelect, message } from 'antd';
const { SHOW_PARENT } = TreeSelect;
import FileSaver from 'file-saver';
import { action, computed, observable, toJS } from 'mobx';
import { MyModelCardModel } from './components/modelCard';

export class Model {
  @observable public wxCode: string;
  @observable public modelCardDataLoading: boolean;
  @observable public myModelCardStore = new MyModelCardModel();
  constructor() {
    this.init();
    this.getCardList();
  }

  public videoModalStore = new VideoModalStore();
  @observable public anchorInfoModel: AnchorInfoModel = new AnchorInfoModel();
  @observable public chooseUserStore: ChooseEmployerModel = new ChooseEmployerModel();
  @observable public chooseUserModal = false;
  @observable public reasonEditModal = false;
  @observable public reasonEditModalTitle = ''; // 推荐理由弹窗title
  @observable public loading = false;
  @observable public tagsModal = false;
  @observable public allSwitch = false;

  @observable public tagsModalTitle = ''; // 编辑标签title
  @observable public pageParams: {
    sord: 'desc' | 'asc' | '' | null;
    sidx: string;
    pageNum: number;
    pageSize: number;
    total: number;
  } = {
    pageNum: 1,
    pageSize: 20,
    total: 0,
    sidx: '',
    sord: '',
  };
  @observable public addCity: string[] = [];

  @observable public categoryIdList: string[] = [];
  @observable public cardList: SliceInfoVO[] = [];
  @observable public chooseCardIds: number[] = [];
  @observable public chooseCopy = false; // 复制切片链接
  @observable public allTags: any[] = []; // 所有标签
  @observable public chooseTags: any[] = []; // 选择的标签
  @observable public recommendReason = ''; // 推荐理由
  // @observable public cityAddList: string[] = [];
  // @observable public cityIdList: string[] = [];

  public filterSet: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        span: 6,
        labelWidth: 120,
        field: 'name',
        label: '主播姓名',
      },
      // {
      //   type: 'select',
      //   span: 6,
      //   labelWidth: 120,
      //   field: 'brokerId',
      //   label: '经纪人',
      // },
      {
        type: 'treeSelect',
        span: 6,
        labelWidth: 120,
        treeCheckable: true,
        multiple: true,
        allowClear: true,
        field: 'cityIdList',
        label: '期望工作城市',
        showCheckedStrategy: SHOW_PARENT,
        onChange: (ids: string[]) => {
          this.changeCityIds(ids);
          // this.getSelectCity();
        },
        onSelect: (value) => {
          console.log(value);
        },
      },
      {
        type: 'treeSelect',
        span: 6,
        labelWidth: 120,
        treeCheckable: true,
        multiple: true,
        field: 'liveTypeList',
        label: '播过的类目',
      },

      {
        type: 'treeSelect',
        span: 6,
        labelWidth: 120,
        treeCheckable: true,
        multiple: true,
        field: 'serviceTypeList',
        label: '服务名称',
      },
      {
        type: 'treeSelect',
        span: 6,
        labelWidth: 120,
        treeCheckable: true,
        multiple: true,
        field: 'sysTagIdList',
        label: '标签选择',
      },
    ],
    handleSearch: () => this.getCardList(),
  });

  public init = () => {
    this.getDict();
  };

  @action private changeCityIds = (ids: string[]) => {
    const filterItem: any = this.filterSet.filterItems.getFilterItem('cityIdList');
    const allIds = extractValuesByKey(toJS(filterItem?.treeData), 'value', 'children');
    const prevIsCheckedAll = filterItem?.value?.includes('0');
    const nowIsCheckedAll = ids?.includes('0');
    if (ids[ids?.length - 1] === '0' || (prevIsCheckedAll && !nowIsCheckedAll)) {
      this.filterSet.filterItems.updateFilterItem([
        {
          field: 'cityIdList',
          value: prevIsCheckedAll && !nowIsCheckedAll ? [] : allIds,
        },
      ]);
    } else {
      if (ids.filter((item) => item !== '0')?.length === allIds.filter((item) => item !== '0')?.length) {
        this.filterSet.filterItems.updateFilterItem([{ field: 'cityIdList', value: allIds }]);
        return;
      }
      this.filterSet.filterItems.updateFilterItem([{ field: 'cityIdList', value: ids.filter((item) => item !== '0') }]);
    }
  };

  @action private getDict = async () => {
    request<PaginationData<BrokerVO>>({
      url: API.getBrokerList,
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    }).then((res) => {
      const brokerList = res.data?.list?.map((e) => {
        return { label: e.name, value: e.id.toString() };
      });
      this.filterSet.filterItems.updateFilterItem([{ field: 'brokerId', data: brokerList }]);
    });

    request<PaginationData<any>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    }).then((res) => {
      this.filterSet.filterItems.updateFilterItem([
        {
          field: 'liveTypeList',
          treeData: transformKeys(res.data, {
            name: 'label',
            id: 'value',
            childrenList: 'children',
          }),
        },
      ]);
    });

    request<BaseData<any>>({
      url: api.system.getDictMap,
      method: 'post',
      data: {
        dictTypeList: ['service_name'],
      },
    }).then((res) => {
      //服务名称
      const serviceName = res.data?.service_name?.map((e: { dictName: any; dictValue: any }) => {
        return { label: e.dictName, value: e.dictValue };
      });
      this.filterSet.filterItems.updateFilterItem([{ field: 'serviceTypeList', treeData: serviceName }]);
    });

    request<BaseData<any>>({
      url: '/baseinfo/area/province/city/list',
      method: 'get',
    }).then((res) => {
      //城市名称
      let transformedData = transformKeys(res.data, {
        name: 'label',
        id: 'value',
        childrenList: 'children',
      });

      const allCity = {
        label: '全国',
        value: '0',
        children: undefined,
      };
      const processChildren = (nodes: any) => {
        return nodes.map((item) => {
          if (item.children && item.children.length === 1) {
            return {
              ...item,
              ...item.children[0],
              children: undefined,
            };
          } else if (item.children) {
            return item;
          }
          return item;
        });
      };
      transformedData = processChildren(transformedData);
      transformedData = [allCity, ...transformedData];
      this.filterSet.filterItems.updateFilterItem([{ field: 'cityIdList', treeData: transformedData }]);
    });

    request({
      url: '/pbb/platform/sys/tag/lib/list/all',
      method: 'GET',
      params: {
        categoryType: 2,
      },
    }).then((res: any) => {
      this.filterSet.filterItems.updateFilterItem([
        {
          field: 'sysTagIdList',
          treeData: transformKeys(res.data, {
            name: 'label',
            id: 'value',
          }),
        },
      ]);
    });
  };

  @action public getCardList = async () => {
    this.loading = true;
    this.cardList = [];
    const params = this.filterSet.filterItems.params;

    if (params?.liveTypeList) {
      const ids = new Set<string>(params.liveTypeList.split(','));
      const dataCell = this.filterSet.filterItems.originData.find(
        (item) => item.field === 'liveTypeList',
      ) as FilterTreeSelect;
      const filterTree = (data: FilterTreeSelectItem[], ids: Set<string>) => {
        const filterNode = (node: FilterTreeSelectItem) => {
          const filteredChildren = node.children.map(filterNode).filter((child) => child !== null);
          if (ids.has(node.value) || filteredChildren.length > 0) {
            return {
              ...node,
              children: filteredChildren,
            };
          }
          return null;
        };
        return data.map(filterNode).filter((node) => node !== null);
      };
      const levels = filterTree(dataCell.treeData, ids);
      params.liveTypeList = transformKeys(levels, {
        children: 'childrenList',
        value: 'id',
        label: 'name',
      });
    }
    if (params?.cityIdList) {
      params.cityIdList = params.cityIdList.split(',');
    }
    if (params?.serviceTypeList) {
      params.serviceTypeList = params.serviceTypeList.split(',');
    }
    if (params?.sysTagIdList) {
      params.sysTagIdList = params.sysTagIdList.split(',');
    }
    const res = await request<PaginationData<SliceInfoVO>>({
      url: '/pbb/platform/sys/slice/list',
      method: 'POST',
      data: {
        ...params,
        ...this.pageParams,
      },
    });
    this.cardList = res.data.list;
    this.pageParams.total = res.data.total;
    this.loading = false;
    return res;
  };

  @action public getAllTags = () => {
    request({
      url: '/pbb/platform/sys/tag/lib/list/all',
      method: 'GET',
      params: {
        categoryType: 2,
      },
    }).then((res: any) => {
      this.allTags = transformKeys(
        res?.data?.filter((item: { children: string | any[] }) => item.children.length),
        {
          name: 'title',
          id: 'value',
        },
      );
    });
  };

  // @action
  // public getCategory = async () => {
  //   const req = await request<PaginationData<SysTagCategoryVO>>({
  //     method: 'POST',
  //     url: '/pbb/platform/sys/tag/category/list/page',
  //     data: {
  //       pageNum: 1,
  //       pageSize: 9999,
  //       showState: true,
  //       categoryType: 2,
  //     },
  //   });
  //   const categoryIdList: string[] = [];
  //   (req?.data?.list || [])?.forEach((item) => {
  //     categoryIdList.push(String(item.id));
  //     this.getTagList(String(item.id));
  //     this.filterSet.filterItems.addItem([
  //       {
  //         type: 'select',
  //         mode: 'multiple',
  //         span: 6,
  //         labelWidth: 120,
  //         field: String(item.id),
  //         label: item.categoryName,
  //       },
  //     ]);
  //   });
  //   this.categoryIdList = categoryIdList || [];
  // };

  @action getSelectCity = () => {};
  @action
  public getTagList = async (id: string) => {
    const req = await request<PaginationData<SysTagLibVO>>({
      method: 'POST',
      url: '/pbb/platform/sys/tag/lib/list/page',
      data: {
        categoryIdList: [id],
        pageNum: 1,
        pageSize: 9999,
        categoryType: 2,
        showState: true,
      },
    });
    const data = (req?.data?.list || [])?.map((item) => {
      return {
        label: item.libName,
        value: item.id,
      };
    });
    const dict: any = { [id]: data };
    this.filterSet.filterItems.addDict(dict);
  };

  // 打开推荐弹窗
  @action public openRecommend = (id?: number) => {
    if (id) {
      this.chooseCardIds = [id];
    }
    if (!this.chooseCardIds?.length) {
      message.info('请选择推荐切片');
      return;
    }
    this.chooseCopy = false;
    this.chooseUserModal = true;
    this.chooseUserStore.mainStore.grid.onQuery();
  };

  // 打开编辑标签弹窗
  @action public openEditTags = (id?: number) => {
    this.chooseTags = [];
    this.tagsModalTitle = '批量添加切片标签';
    if (id) {
      this.tagsModalTitle = '编辑切片标签';
      this.chooseCardIds = [id];
      this.chooseTags = this.cardList.find((item) => item.opusId === id)?.sysTagVOList?.map((item) => item.libId);
    }
    if (!this.chooseCardIds?.length) {
      message.info('请选择推荐切片');
      return;
    }
    this.getAllTags();
    this.tagsModal = true;
  };

  @action public openAnchorInfoModal = (userId: string) => {
    if (userId) {
      this.anchorInfoModel.onShow(userId);
    } else {
      message.info('请选择用户');
      return;
    }
  };

  // 打开编辑推荐理由弹窗
  @action public openReasonEdit = (id?: number) => {
    this.recommendReason = '';
    this.reasonEditModalTitle = '批量编辑推荐理由';
    if (id) {
      this.reasonEditModalTitle = '编辑推荐理由';
      this.chooseCardIds = [id];
      this.recommendReason = this.cardList?.find((item) => item.opusId === id)?.butlerEvaluation;
    }
    if (!this.chooseCardIds?.length) {
      message.info('请选择推荐切片');
      return;
    }
    this.reasonEditModal = true;
  };

  @action public onRecommend = () => {
    const selectUserIds = Array.from(this.chooseUserStore.mainStore.grid.gridModel.selectedIds);
    if (!selectUserIds?.length) {
      message.info('请选择雇主～');
      return;
    }
    const chooseUserList = Array.from(toJS(this.chooseUserStore.mainStore.grid.gridModel.rows))?.filter((item) =>
      selectUserIds.includes(item.id),
    );
    const chooseOpusList = this.cardList?.filter((item) => this.chooseCardIds.includes(item.opusId));
    const putOpusList = chooseOpusList.map((item) => ({
      opusId: item.opusId,
      userId: item.userId,
      butlerReview: item.butlerReview,
    }));
    const putList = chooseUserList.map((item) => {
      return {
        employerId: item.id,
        dtoList: putOpusList,
      };
    });

    request({
      url: '/pbb/platform/sys/butler/recommend',
      method: 'POST',
      data: putList,
    }).then(() => {
      this.chooseUserModal = false;
      message.success('推荐成功～');
      if (this.chooseCopy) {
        this.copyLink();
      }
    });
  };
  /** 下载模卡 */
  @action public onModelCardDownload = async (item: SliceInfoVO) => {
    try {
      const res = await request<BaseData<AnchorDetail>>({
        url: '/pbb/platform/anchor/detail',
        method: 'POST',
        data: {
          id: item.userId,
        },
      });
      if (res?.data?.muckUpUrl) {
        const fileExt = res.data.muckUpUrl.split('.').pop();
        FileSaver.saveAs(res.data.muckUpUrl, `主播模卡-${item.name}.${fileExt}`);
      } else {
        if (res.data) {
          await this.myModelCardStore.getWxCode(String(item.userId));
          this.myModelCardStore.setUserData(res.data);
          setTimeout(() => {
            this.myModelCardStore.downPic(`主播模卡-${item.name}`);
          });
        } else {
          message.error('获取模卡失败');
        }
      }

      // await
    } catch (error) {
      throw new Error();
    }
  };

  @action public onChangeModal = (key: string, visible: boolean) => {
    this[key] = visible;
  };

  @action public onPageChange = (pageNum: number, pageSize: number) => {
    this.cardList = [];
    if (pageSize !== this.pageParams.pageSize) {
      this.pageParams.pageNum = 1;
    } else {
      this.pageParams.pageNum = pageNum;
    }
    this.pageParams.pageSize = pageSize;
    this.getCardList();
  };

  @action public onCheckCard = (id: number) => {
    const findIndex = this.chooseCardIds.indexOf(id);
    if (findIndex === -1) {
      this.chooseCardIds.push(id);
    } else {
      this.chooseCardIds.splice(findIndex, 1);
    }
  };

  @computed
  public get checkedAll(): boolean {
    return this.cardList.length === this.chooseCardIds.length && this.chooseCardIds.length !== 0;
  }

  @action public chooseAllCard = (e: { target: { checked: any } }) => {
    if (e.target.checked) {
      this.chooseCardIds = this.cardList.map((item) => item.opusId);
    } else {
      this.chooseCardIds = [];
    }
  };

  @action public onChooseCopy = (isCheck: boolean) => {
    this.chooseCopy = isCheck;
  };

  @action public onSelectTag = (tagsIds: any[]) => {
    this.chooseTags = tagsIds;
  };

  @action public onSort = (sidx: string, sord: 'desc' | 'asc' | '') => {
    this.cardList = [];
    this.pageParams = {
      ...this.pageParams,
      sidx,
      sord,
    };
    this.getCardList();
  };

  @action public onKeepTags = () => {
    const chooseOpusList = this.cardList?.filter((item) => this.chooseCardIds.includes(item.opusId));
    if (!this.chooseTags?.length) {
      message.info('请选择标签～');
    }
    request({
      url: '/pbb/platform/sys/tag/relation/add/batch',
      method: 'POST',
      data: {
        bizId: chooseOpusList.map((item) => item?.opusId)?.join(','),
        libId: this.chooseTags?.join(','),
        categoryType: 2,
      },
    }).then(() => {
      this.tagsModal = false;
      message.success('编辑成功～');
      this.getCardList();
    });
  };

  // 选中全国城市
  @action public checkAllCity = (ids: string[]) => {
    if (ids.includes('0')) {
      this.allSwitch = true;
    } else {
    }
  };

  @action public getAllNodeKeys = (nodes) => {
    return nodes.reduce((keys, node) => {
      const { key, children } = node;
      keys.push(key);
      if (children) {
        keys = keys.concat(this.getAllNodeKeys(children));
      }
      return keys;
    }, []);
  };

  @action public onKeepReason = () => {
    if (!this.recommendReason) {
      message.info('推荐理由不能为空～');
    }
    const chooseOpusList = this.cardList?.filter((item) => this.chooseCardIds.includes(item.opusId));
    request({
      url: '/pbb/platform/sys/update/butler/review',
      method: 'POST',
      data: {
        opusId: chooseOpusList.map((item) => item.opusId),
        butlerReview: this.recommendReason,
      },
    }).then(() => {
      this.reasonEditModal = false;
      message.success('修改成功～');
      this.getCardList();
    });
  };

  @action public changeReason = (str: string) => {
    this.recommendReason = str;
  };

  public copyLink = () => {
    getWxUrl({
      path: '/pages/findAnchor/index',
      query: 'isRecommended=1',
      environment: 1,
      source: 2,
      channel: null,
    }).then((res) => {
      try {
        navigator.clipboard.writeText(res);
        alert('已复制到粘贴板');
      } catch (err) {
        alert(`复制失败: ${err}`);
      }
    });
  };

  public copyLinkCell = (cell: SliceInfoVO) => {
    request<BaseData<string>>({
      url: '/pbb/platform/anon/getUrlLink',
      method: 'POST',
      data: {
        anchorId: cell?.userId,
        opusId: cell?.opusId,
        envVersion: window.location.href.includes('test.')
          ? 'trial'
          : window.location.href.includes('pre.')
            ? 'trial'
            : 'release',
        environment: 1,
        channel: 5,
      },
    }).then((res) => {
      if (!res?.data) {
        message.error('获取链接失败');
        return;
      }
      try {
        navigator.clipboard.writeText(res?.data);
        message.success('已复制到粘贴板');
      } catch (err) {
        message.error(`复制失败: ${err}`);
      }
    });
  };
}
